<template>
  <div
    class="w-full h-full flex"
    style="position: absolute; z-index: 9999; top: 0; left: 0;"
    @click="$emit('closeModal')"
  >
    <div
      class="absolute"
      :style="`top:${top}; left:${left}; min-height: 181px; width:${width};`"
    >
      <Card
        class="w-full h-full"
        style="padding: 9px 18px;"
      >
        <div
          class="text-lg flex text-romanSilver font-bold pb-3 flex"
          v-if="hasTitleSlot"
          style="border-bottom: 1px dashed #878E99; margin: 1.125rem 0"
        >
          <slot name="title" />
        </div>
        <div
          class="w-full h-auto my-2 text-jet"
        >
          <slot />
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from "./Card";

export default {
  name: "SideModal",
  components: { Card },
  props: {
    top: {
      default: '0',
      type: String,
    },
    left: {
      default: '0',
      type: String,
    },
    width: {
      default: '300px',
      type: String,
    },
  },
  computed: {
    hasTitleSlot() {
      return !!this.$slots.title;
    },
  },
};
</script>
