<template>
  <div
    class="pt-5 pb-5 w-full"
  >
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.push({ name: 'PerformanceSettings' })"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878E99; height:1.5rem"
      />

      <div style="width: 200px; margin-top: 0.1rem;">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          Template Usage
        </h1>
      </div>
      <div
        class="w-7/12"
        style="margin-top: 1.2rem;"
      >
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div class="px-3">
      <card
        class="mt-6"
        style="padding: 20px;"
      >
        <CardFooter>
          <template v-slot:search>
            <SearchInput
              placeholder="Search"
              class="search"
              style="width : 320px; margin-top: 0.1rem;"
            />
          </template>
          <template v-slot:calendar>
            <div
              class="flex"
              style="width: 180px; padding: 5px 8px;"
            >
              <icon
                icon-name="calendar"
                class="icon mt-1"
              />
              <div
                class="border-r"
                style="width: 1px; margin: 8px 8px; border-color: #321C3B;"
              />
              <div
                class="text-darkPurple h-full"
                style="width: 91px; font-size: 16px; margin: 4px 6px"
              >
                Year to Date
              </div>
              <icon
                icon-name="caret-down"
                class="icon mt-1"
              />
            </div>
          </template>
        </CardFooter>
      </card>
      <div
        class="w-full mt-3"
        style="overflow-x: scroll;"
      >
        <Table
          :headers="templateHeader"
          :items="templateData"
          style="width:1300px;"
          class="mt-1"
          :has-checkbox="false"
          :has-number="false"
          :loading="false"
        >
          <template v-slot:item="{ item }">
            <div
              v-if="item.cycle"
              style="width:189px"
              @click="cycleModal = true"
            >
              <Badge
                :label="item.data.cycle"
                variant="primary cursor-pointer"
                background-color="rgba(135, 142, 153, 0.15)"
                color="#321C3B"
              />
            </div>
            <div
              v-else-if="item.agreements"
              style="width:189px"
              @click="agreementsModal = true"
            >
              <Badge
                :label="item.data.agreements"
                variant="primary cursor-pointer"
                background-color="rgba(135, 142, 153, 0.15)"
                color="#321C3B"
              />
            </div>
            <div
              class="flex"
              v-else-if="item.employees"
              v-for="(employees, index) in item.data.employees"
              :key="index"
            >
              <div
                style="width: 128px; height: 58px; padding: 15px 0px;"
              >
                <Badge
                  :label="employees.location"
                  variant="primary"
                  background-color="rgba(33, 118, 255, 0.15)"
                  color="#2176FF"
                />
              </div>
              <div
                style="width: 128px; height: 58px; padding: 15px 0px;"
              >
                <Badge
                  :label="employees.level"
                  variant="primary"
                  background-color="rgba(233, 147, 35, 0.15)"
                  color="#E99323"
                />
              </div>
              <div
                style="width: 128px; height: 58px; padding: 15px 0px;"
                @click="functionsModal = true"
              >
                <Badge
                  :label="employees.functions"
                  variant="primary cursor-pointer"
                  background-color="rgba(73, 1, 102, 0.15)"
                  color="#490166"
                />
              </div>
              <div
                style="width: 128px; height: 58px; padding: 15px 0px;"
                @click="designationsModal = true"
              >
                <Badge
                  :label="employees.designations"
                  variant="primary cursor-pointer"
                  background-color="rgba(19, 181, 106, 0.15)"
                  color="#13B56A"
                />
              </div>
            </div>
            <div
              class="flex"
              v-else-if="item.id"
            >
              <icon
                icon-name="icon-edit"
                class="editIco"
              />
            </div>
          </template>
        </Table>
      </div>
    </div>
    <transition name="fadeIn">
      <SideModal
        v-if="cycleModal"
        top="20%"
        left="34%"
        @closeModal="cycleModal = false"
      >
        <template v-slot:title>
          <div class="text-darkPurple -mt-2 w-11/12">
            FY2021 Appraisal Cycle
          </div>
        </template>
        <div class="w-full my-4">
          <div class="uppercase text-romanSilver text-xs font-black my-1">
            Description
          </div>
          <div
            class="text-jet text-base"
            style="letter-spacing: 0.015em;"
          >
            Discussed appraisal cycle for the year 2021
          </div>
        </div>
        <div class="w-full mb-4">
          <div class="uppercase text-lightOrange text-xs font-black my-1">
            Period
          </div>
          <div
            class="text-jet text-base"
            style="letter-spacing: 0.015em;"
          >
            January 09, 2021 - December 24, 2021
          </div>
        </div>
        <div class="w-full mb-4">
          <div class="uppercase text-blueCrayola text-xs font-black my-1">
            First Appraisal
          </div>
          <div
            class="text-jet text-base"
            style="letter-spacing: 0.015em;"
          >
            June 02, 2021 - June 17, 2021
          </div>
        </div>
        <div class="w-full mb-4">
          <div class="uppercase text-carrotOrange text-xs font-black my-1">
            Mid-Year Review
          </div>
          <div
            class="text-jet text-base"
            style="letter-spacing: 0.015em;"
          >
            June 12, 2021 - July 31, 2021
          </div>
        </div>
        <div class="w-full mb-4">
          <div class="uppercase text-mediumSeaGreen text-xs font-black my-1">
            Final Appraisal
          </div>
          <div
            class="text-jet text-base"
            style="letter-spacing: 0.015em;"
          >
            December 26, 2021 - January 12, 2021
          </div>
        </div>
        <div class="w-full font-black uppercase text-flame">
          See All Details
        </div>
      </SideModal>
    </transition>
    <transition name="fadeIn">
      <SideModal
        v-if="agreementsModal"
        top="25%"
        left="47%"
        @closeModal="agreementsModal = false"
      >
        <template v-slot:title>
          <div class="text-darkPurple -mt-2 w-11/12">
            Organizational Goals
          </div>
        </template>
        <div class="text-base text-jet">
          FY2021 Organizational Goals
        </div>
        <div
          class="text-lg flex text-romanSilver font-bold pb-3 flex"
          style="border-bottom: 1px dashed #878E99; margin: 1.125rem 0"
        >
          Employee Agreements
        </div>
        <div
          class="text-base text-darkPurple mb-4"
          v-for="(e, index) in employeeAgreement"
          :key="index"
        >
          {{ e.name }}
        </div>
        <div class="w-full font-black uppercase text-blueCrayola">
          See More
        </div>
      </SideModal>
    </transition>
    <transition name="fadeIn">
      <SideModal
        v-if="functionsModal"
        top="40%"
        left="52%"
        @closeModal="functionsModal = false"
      >
        <template v-slot:title>
          <div class="text-darkPurple -mt-2 w-11/12">
            Functions
          </div>
        </template>
        <div
          class="text-base text-darkPurple mb-4"
          v-for="(f, index) in functionsData"
          :key="index"
        >
          {{ f.name }}
        </div>
      </SideModal>
    </transition>
    <transition name="fadeIn">
      <SideModal
        v-if="designationsModal"
        top="40%"
        left="61%"
        @closeModal="designationsModal = false"
      >
        <template v-slot:title>
          <div class="text-darkPurple -mt-2 w-11/12">
            Designations
          </div>
        </template>
        <div
          class="text-base text-darkPurple mb-4"
          v-for="(d, index) in designationsData"
          :key="index"
        >
          {{ d.value }}
        </div>
      </SideModal>
    </transition>
  </div>
</template>

<script>
import BackButton from '@scelloo/cloudenly-ui/src/components/back-button';
import Breadcrumb from '@scelloo/cloudenly-ui/src/components/bread-crumb';
import SearchInput from '@scelloo/cloudenly-ui/src/components/search-input';
import Badge from '@scelloo/cloudenly-ui/src/components/badge';
import Table from '@scelloo/cloudenly-ui/src/components/table';
import Card from '@/components/Card';
import CardFooter from '@/components/CardFooter';
import Icon from "@/components/Icon";
import SideModal from '@/components/SideModal';

export default {
  name: "TemplateUsage",
  components: {
    BackButton,
    Breadcrumb,
    SearchInput,
    Card,
    CardFooter,
    SideModal,
    Table,
    Badge,
    Icon,
  },
  data() {
    return {
      cycleModal: false,
      agreementsModal: false,
      functionsModal: false,
      designationsModal: false,
      breadcrumbs: [
        {
          disabled: false, text: "Settings", href: "settings", id: "Settings",
        },
        {
          disabled: false, text: "Performance", href: "performance", id: "Performance",
        },
        {
          disabled: true, text: "Template Usage", id: "Template Usage",
        },
      ],
      templateHeader: [
        { title: 'TEMPLATE', value: 'template', filter: true },
        { title: 'CYCLE USED', value: 'cycle' },
        { title: 'AGREEMENTS', value: 'agreements' },
        { title: 'APPLIED TO EMPLOYEES IN', value: 'employees' },
        {
          title: '', value: 'id', image: true,
        },
      ],
      templateData: [
        {
          template: 'Performance Template B',
          cycle: 'FY2021 Appraisal Cycle',
          agreements: "13 Agreements",
          employees: [
            {
              location: 'All locations',
              level: 'Level 4',
              functions: '3 Functions',
              designations: '3 Designations',
            },
          ],
          id: '1',
        },
        {
          template: 'Performance Template A',
          cycle: 'FY2020 Appraisal Cycle',
          agreements: "4 Agreements",
          employees: [
            {
              location: 'Swirl Media HQ',
              level: 'All Levels',
              functions: 'Sales and Marke...',
              designations: '12 Designations',
            },
          ],
          id: '1',
        },
      ],
      functionsData: [
        { name: "Technology" },
        { name: "Sales and Marketing" },
        { name: "Operations" },
      ],
      employeeAgreement: [
        { name: "Ahmed Abdul" },
        { name: "Amande Jonathan" },
        { name: "Chimdimna Ekeoma" },
        { name: "Esther Klinsman" },
        { name: "King Ladipo" },
      ],
      designationsData: [
        { value: "Software Engineer" },
        { value: "Digital Marketer" },
        { value: "Field Maintenance Technician" },
      ],
    };
  },
};
</script>

<style scoped>
.icon
{
  width: 24px;
  height: 24px;
  padding: 3px;
}
.editIco:nth-child(odd)
{
    color:#2176FF;
}
.editIco:nth-child(even)
{
   color: #878E99;
}
</style>
